<template>
    <div v-if="page">
        <h2>{{ $t('freedom-program') }}</h2>
        <div v-if="!!page.doc.content" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>
        <div class="row justify-content-center mb-3" v-if="!!page.lockedRate && !!page.viewer.depositAddress">
            <locked-rate-freedom-info :locked-rate="page.lockedRate" :address="page.viewer.depositAddress"
                :packages="freedom" />
        </div>

        <div v-else-if="page.freedomProgramCount < 3">

            <template v.for="page.freedomProgram">>
                <div class="row justify-content-center mb-3">
                    <freedom-program-package-box :programType="'Freedom'" :programDeposit="100" :key="100"
                        :reward-rate-min="6" :reward-rate-max="18" :value="100" :available="page.viewer.totals.tokens"
                        :freedomProgramName="'Advanced'" :rate="page.lockedRate"
                        :deposit-address="page.viewer.depositAddress" :thumbnail="'/static/freedom/advanced.png'"
                        :packId="'100-fp2'" :freedomProgramCount="page.freedomProgramCount" :eurUsd="eurUsd" />
                    <freedom-program-package-box :programType="'Freedom'" :programDeposit="200" :key="200"
                        :reward-rate-min="9" :reward-rate-max="20" :value="200" :available="page.viewer.totals.tokens"
                        :freedomProgramName="'Premium'" :rate="page.lockedRate"
                        :deposit-address="page.viewer.depositAddress" :thumbnail="'/static/freedom/premium.png'"
                        :packId="'200-fp2'" :freedomProgramCount="page.freedomProgramCount" :eurUsd="eurUsd" />

                </div>
            </template>
        </div>
        <div v-else>
            <template>
                <div class="card-body">
                    <v-card class="mx-auto" style="padding-bottom: 10px;" max-width="400">
                        <v-card-text class="pb-0 card-title-htl fsize-2" style="text-align: center; color:red;">
                            Allready activated 3 programs simultaneously
                        </v-card-text>
                    </v-card>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import FreedomProgramPackageBox from "../components/Packages/FreedomProgramPackageBox";
import LockedRateFreedomInfo from "../components/Packages/LockedRateFreedomInfo";
import gql from "graphql-tag";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import { mapGetters } from "vuex";
import PageTitle from "@/components/common/PageTitle";
export default {
    name: "FreedomProgramPage",
    components: {
        FreedomProgramPackageBox,
        LockedRateFreedomInfo,
        PageTitle
    },
    data() {
        return {

            freedom:
                [
                    {
                        color: "primary",
                        id: "50-fp2",
                        name: "Basic",
                        price: 50,
                        rewardRate: 0,
                        rewards: null,
                        thumbnail: "https://panel.hotelium.io/static/freedom/basic.png"
                    },
                    {
                        color: "primary",
                        id: "100-fp2",
                        name: "Advanced",
                        price: 100,
                        rewardRate: 0,
                        rewards: null,
                        thumbnail: "https://panel.hotelium.io/static/freedom/advanced.png"
                    },
                    {
                        color: "primary",
                        id: "200-fp2",
                        name: "Premium",
                        price: 200,
                        rewardRate: 0,
                        rewards: null,
                        thumbnail: "https://panel.hotelium.io/static/freedom/premium.png"
                    }
                ],
            page: {
                doc: {
                    content: null
                },
                lockedRate: null,
                viewer: null,
                packages: null,


            }
        }
    },
    methods: {

        // getPrograms() {
        //     var result = {programType:"100",programDeposit:100 };

        //     console.log("this.page.freedomProgram");
        //     console.log(this.page.freedomProgram);

        //     /*for (const key in this.page.freedomProgram) {
        //         console.log("key");
        //         console.log(key);
        //         console.log("this.page.freedomProgram[key]");
        //         console.log(this.page.freedomProgram[key]);

        //         result.programType = key;
        //         result.programDeposit = this.page.freedomProgram[key].depositValueInFiat;
        //     }*/

        //     console.log("result");
        //     console.log(result);

        //     return result;
        // }
    },
    computed: {
        packet() {
            return null;//this.$store.state.packages.lockedPacket;
        },
        ...mapGetters({
            available: 'user/available/tokens',
            token: 'prices/rate',
            rate: 'prices/htlEur',
            eurUsd: 'prices/eurUsd',
        })
    },
    apollo: {
        page: {
            query: gql`query($lang:String){doc(id:"join-freedom-program"){content,title},
packageCategories(lang:$lang){id,name,packages{id,name,price,rewardRate,rewards,thumbnail,color}},freedomProgram,freedomProgramCount,
lockedRate{expiresAt,rate,rateCrypto,token,pack,currency,htlRate,upgrade,price,programType,programDeposit},viewer{id,depositAddress{id,address,currency,expiresAt,lockedRate},totals{tokens}},viewerIsAdmin}`,
            variables: {
                lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
            },
            update: data => {
                data.packages = data.packageCategories.flatMap(p => p.packages)
                // console.log("data");
                // console.log(data);
                // console.log("data.packages");
                // console.log(data.packages);

                // console.log("data.lockedRate");
                // console.log(data.lockedRate);

                try {
                    data.freedomProgram = JSON.parse(data.freedomProgram);
                    data.programTypes = Object.keys(data.freedomProgram);
                }
                catch (ex) {
                    console.log(ex);
                }

                // for(var ii=0;ii<data.packages.length;ii++) {
                //     console.log("data.packages[ii].id")
                //     console.log(data.packages[ii].id)
                //     console.log("data.packages[ii].name")
                //     console.log(data.packages[ii].name)                        
                //     console.log("data.packages[ii].price")
                //     console.log(data.packages[ii].price)                        
                // }

                return data
            },
            result({ data }) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }

        }
    }
}
</script>

<style scoped></style>
